/*
 * Contributors: Fred Lionetti
 *
 * Libraries used: MUI React
 *
 * File description: Calls for the list of patients who need studies linked and renders a 
 * individual component for each patient.
 */

import { useEffect, useRef, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {IdentifyStudy} from './identify_study';
import {urls} from './constants';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiListItem from "@material-ui/core/ListItem";
import Typography from '@mui/material/Typography';
const base_url = urls.base_url;

/**
* API call to access patients from the CMR table
* who currently have no Study data.
*
* @params inside props authToken: current users authorization token, logoutCallback: call to function the logs out user
* @return renders list of patients with no study data linked and studies available for linking
*/
export function IdentifyStudies(props) {
    let authToken = props.authToken;
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState([true])
    const[confirmAlert, setconfirmAlert] = useState(0);
    //console.log("authToken here:", authToken);

    function updateTable(table_name) {
        let url = `${base_url}/identify_studies`;
        //console.log(url);
        fetch(url, {headers:{"Authorization":authToken}})
        .then(response => response.json())
        .then(data => {
          if(data.message === "The incoming token has expired") {
            props.logoutCallback(true);
          }
            setLoading(false);
            //console.log("got data:", data);
            setRows(data);
            //console.log("row length: ", rows.length)
            //console.log(table_name)
            if(table_name === undefined){
              setconfirmAlert(1);
            }
        }).catch(err=>{
          //console.log("Failed to fetch!", props.logoutCallback);
          props.logoutCallback(true);
      });
    }

    const handleAlertClose = (event) => {
      setconfirmAlert(0);
    };

    useEffect(() => {
      //console.log("Loaded app!:");
      //console.log("table_name:", props.table_name)
      updateTable("CMR")
    }, [])

      return (
        <>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={confirmAlert === 1} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="success">
            <AlertTitle  style={{fontSize: 20,  }}>Study linked to patient</AlertTitle>
                    Study has successfully linked to corresponding patient.
          </Alert>
        </Snackbar>
          {
            loading 
            ? <>
            <CircularProgress size={100} />
            </>
            : rows.length === 0
            ? <>
            <p>There is no available Study to link.</p>
            </>
            : <>
            <h4>Link uploaded studies to CMR entries that currently do not have a study associated with them.</h4>
            <MuiListItem disablePadding> <Typography sx={{fontSize: 16}}>{"\u2B58 Linking a study to a CMR entry will populate the “Study” column in the CMR table."}</Typography> </MuiListItem>
            <MuiListItem disablePadding> <Typography sx={{fontSize: 16, marginBottom: 5}}>{"\u2B58 Please “confirm” each link below one at a time, before linking the next study in the list below."}</Typography> </MuiListItem>
            {
            rows.map((value) => (<IdentifyStudy key={value[0]+"-"+value[1]} updateTable={updateTable} patient_id={value[0]} age={value[1]} authToken={authToken} potential_studies={value[2]} loading={loading} setLoading={setLoading}>{value[0]+"-"+value[1]}</IdentifyStudy>))
            }
            </>
          }
        </>
      );
  }
