import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    )) (({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
}));

const table_columns = { 
    "ID": {
        field: "ID",
        headerName: "ID",
        editable: false,
        width: 200
    },
    "age": {
        field: "age",
        type: 'number',
        headerName: "Age",
        width: 200,
        align: "left",
        headerAlign: "left",
        editable: false
    },
    "Study": {
        field: "Study",
        headerName: "Study",
        renderHeader: () => (
            <LightTooltip placement="right-start" >
            <span>Study</span>
            </LightTooltip>
        ),
        editable: false,
        width: 200
    },
    "Study Type": {
        field: "Study Type",
        headerName: "Study Type",
        renderHeader: () => (
            <LightTooltip placement="right-start" >
            <span>Study Type</span>
            </LightTooltip>
        ),
        editable: false,
        width: 200
    },
    // START of Patient Headers
    /*
    * if column is cohort add tooltip for each entry type
    */
    "Cohort": {
        field: "Cohort",
        headerName: "Cohort",
        editable: true,
        width: 200,
        renderCell: (params) => (
            params.value === "ToF" ?
            <LightTooltip placement="right-start" title={"Tetralogy of Fallot"}>
            <span>{params.value}</span>
            </LightTooltip>
            : params.value === "LVH/HCM" ?
            <LightTooltip placement="right-start" title={"Left Ventricular Hypertrophy/ Hypertrophic cardiomyopathy"}>
            <span>{params.value}</span>
            </LightTooltip>
            : params.value === "HLHS" ?
            <LightTooltip placement="right-start" title={"Hypoplastic Left Heart Syndrome"}>
            <span>{params.value}</span>
            </LightTooltip>
            : params.value === "Normal" ?
            <LightTooltip placement="right-start" title={"Normal Heart"}>
            <span>{params.value}</span>
            </LightTooltip>
            : params.value === "Pulmonary HTN" ?
            <LightTooltip placement="right-start" title={"Hyperptension"}>
            <span>{params.value}</span>
            </LightTooltip>
            :
            <LightTooltip placement="right-start" title={params.value}>
            <span>{params.value}</span>
            </LightTooltip>
        )
    },
    // END of Patients Headers
    // START of ECHO Headers
    "BSA": {
        field: "BSA",
        headerName: "BSA",
        renderHeader: () => (
            <span>BSA [m2]</span>
        ),
        editable: true,
        width: 200
    },
    "IVS": {
        field: "IVS",
        headerName: "IVS",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Interventricular Septum"}>
            <span>IVS [cm]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LVEDD": {
        field: "LVEDD",
        headerName: "LVEDD",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Diastolic Diameter"}>
            <span>LVEDD [cm]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LVESD": {
        field: "LVESD",
        headerName: "LVESD",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Systolic Diameter"}>
            <span>LVESD [cm]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LVPW": {
        field: "LVPW",
        headerName: "LVPW",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular Posterior Wall"}>
            <span>LVPW [cm]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LA": {
        field: "LA",
        headerName: "LA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Atrial Diameter"}>
            <span>LA [cm]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Tricuspid Regurgitation": {
        field: "Tricuspid Regurgitation",
        headerName: "Tricuspid Regurgitation",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Peak Tricuspid Regurgitation Gradient"}>
            <span>Tricuspid Regurgitation [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "tRJet": {
        field: "tRJet",
        headerName: "tRJet",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Tricuspid Regurgitation Doppler Jet"}>
            <span>TR Jet</span>
            </LightTooltip>
        ),
        width: 200
    },
    "TAPSE": {
        field: "TAPSE",
        headerName: "TAPSE",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Tricuspid Annular Plane Systolic Excursion"}>
            <span>TAPSE [cm]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Bullet LVEF": {
        field: "Bullet LVEF",
        headerName: "Bullet LVEF",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Bullet Left Ventricular Ejection Fraction"}>
            <span>Bullet LVEF [%]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RVOT/PS Gradient": {
        field: "RVOT/PS Gradient",
        headerName: "RVOT/PS Gradient",
        editable: true,
        renderHeader: () => (
        <LightTooltip placement="right-start" title={"Right Ventricular Outflow Tract/Pulmonary Stenosis"}>
        <span>RVOT/PS Gradient [mmHg]</span>
        </LightTooltip>
        ),
        width: 200
    },
    // END of Echo Headers
    // START of ECG
    "Heart Rate": {
        field: "Heart Rate",
        headerName: "Heart Rate",
        editable: true,
        renderHeader: () => (
            <span>Heart Rate [bpm]</span>
        ),
        width: 200
    },
    "PR Interval": {
        field: "PR Interval",
        headerName: "PR Interval",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Atrial Depolarization"}>
            <span>PR Interval [ms]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "QRS Duration": {
        field: "QRS Duration",
        headerName: "QRS Duration",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Ventricular Depolarization"}>
            <span>QRS Duration [ms]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "QTc Interval": {
        field: "QTc Interval",
        editable: true,
        renderHeader: () => (
            <span>QTc Interval [ms]</span>
        ),
        headerName: "QTc Interval",
        width: 200
    },
    "Vent Rate": {
        field: "Vent Rate",
        headerName: "Vent Rate",
        renderHeader: () => (
            <span>Vent Rate</span>
        ),
        editable: true,
        width: 200
    },
    "QRS axis": {
        field: "QRS axis",
        headerName: "QRS axis",
        renderHeader: () => (
            <span>QRS Axis [degrees]</span>
        ),
        editable: true,
        width: 200
    },
    // END of ECG Headers
    // START of CATH Headers
    "Pre LV ESP": {
        field: "Pre LV ESP",
        headerName: "Pre LV ESP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Systolic Pressure prior to procedure"}>
            <span>Pre LV ESP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre LV EDP": {
        field:"Pre LV EDP",
        headerName: "Pre LV EDP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Diastolic Pressure prior to procedure"}>
            <span>Pre LV EDP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre RV ESP": {
        field: "Pre RV ESP",
        headerName: "Pre RV ESP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Systolic Pressure prior to procedure"}>
            <span>Pre RV ESP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre RV EDP": {
        field: "Pre RV EDP",
        headerName: "Pre RV EDP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Diastolic Pressure prior to procedure"}>
            <span>Pre RV EDP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre PA ESP": {
        field: "Pre PA ESP",
        headerName: "Pre PA ESP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Pulmonary Artery End Systolic Pressure prior to procedure"}>
            <span>Pre PA ESP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre PA EDP": {
        field: "Pre PA EDP",
        headerName: "Pre PA EDP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Pulmonary Artery End Diastolic Pressure prior to procedure"}>
            <span>Pre PA EDP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre Systolic AP": {
        field: "Pre Systolic AP",
        headerName: "Pre Systolic AP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Aortic Systolic Pressure prior to procedure"}>
            <span>Pre Systolic AP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre Diastolic AP": {
        field: "Pre Diastolic AP",
        headerName: "Pre Diastolic AP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Aortic Diastolic Pressure prior to procedure"}>
            <span>Pre Diastolic AP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Pre Cardiac Index": {
        field: "Pre Cardiac Index",
        headerName: "Pre Cardiac Index",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Cardiac Index Prior to procedure"}>
            <span>Pre Cardiac Index  [L/min/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post LV ESP": {
        field: "Post LV ESP",
        headerName: "Post LV ESP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Systolic Pressure post to procedure"}>
            <span>Post LV ESP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post LV EDP": {
        field: "Post LV EDP",
        headerName: "Post LV EDP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Diastolic Pressure post to procedure"}>
            <span>Post LV EDP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post RV ESP": {
        field: "Post RV ESP",
        headerName: "Post RV ESP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Systolic Pressure post to procedure"}>
            <span>Post RV ESP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post RV EDP": {
        field: "Post RV EDP",
        headerName: "Post RV EDP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Diastolic Pressure post to procedure"}>
            <span>Post RV EDP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post PA ESP": {
        field: "Post PA ESP",
        headerName: "Post PA ESP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Pulmonary Artery End Systolic Pressure post to procedure"}>
            <span>Post PA ESP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post PA EDP": {
        field: "Post PA EDP",
        headerName: "Post PA EDP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Pulmonary Artery End Diastolic Pressure post to procedure"}>
            <span>Post PA EDP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post Systolic AP": {
        field: "Post Systolic AP",
        headerName: "Post Systolic AP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Aortic Systolic Pressure post to procedure"}>
            <span>Post Systolic AP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post Diastolic AP": {
        field: "Post Diastolic AP",
        headerName: "Post Diastolic AP",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Aortic Diastolic Pressure post to procedure"}>
            <span>Post Diastolic AP [mmHg]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "Post Cardiac Index": {
        field: "Post Cardiac Index",
        headerName: "Post Cardiac Index",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Cardiac Index post to procedure"}>
            <span>Post Cardiac Index [L/min/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    // END of CATH Headers
    // START of CPET Headers
    "vo2Max": {
        field: "vo2Max",
        headerName: "vo2Max",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Maximum Oxygen Consumption"}>
            <span>VO2 Max [L/min]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "O2 PULSE PEAK MEASURED": {
        field: "O2 PULSE PEAK MEASURED",
        headerName: "O2 PULSE PEAK MEASURED",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Oxygen Pulse"}>
            <span>O2 Pulse [ml/beat]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "vat": {
        field: "vat",
        headerName: "vat",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Ventilatory Anaerobic Threshold"}>
            <span>VAT [L/min]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "VE/VCO2 @ AT MEASURED": {
        field: "VE/VCO2 @ AT MEASURED",
        headerName: "VE/VCO2 @ AT MEASURED",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Minute Ventilation Relative To CO2 Exhalation"}>
            <span>VE:VCO2</span>
            </LightTooltip>
        ),
        width: 200
    },
    "VO2 MEASURED VALUE @ AT": {
        field: "VO2 MEASURED VALUE @ AT",
        headerName: "VO2 MEASURED VALUE @ AT",
        editable: true,
        renderHeader: () => (
            <span>VO2 MEASURED VALUE @ AT</span>
        ),
        width: 200
    },
    // END of CPET Headers
    // Start of CMR Headers
    "LVEDV": {
        field: "LVEDV",
        headerName: "LVEDV",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Diastolic Volume"}>
            <span>LVEDV [cc]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LVESV": {
        field: "LVESV",
        headerName: "LVESV",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Systolic Volume"}>
            <span>LVESV [cc]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LVEF": {
        field: "LVEF",
        headerName: "LVEF",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular Ejection Fraction"}>
            <span>LVEF [%]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV EDV": {
        field: "RV EDV",
        headerName: "RV EDV",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Diastolic Volume"}>
            <span>RV EDV [cc]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV ESV": {
        field: "RV ESV",
        headerName: "RV ESV",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Systolic Volume"}>
            <span>RV ESV [cc]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV EF": {
        field: "RV EF",
        headerName: "RV EF",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular Ejection Fraction"}>
            <span>RV EF [%]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LV CO": {
        field: "LV CO",
        headerName: "LV CO",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular Cardiac Output"}>
            <span>LV CO [L/min]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LV CO index to BSA": {
        field: "LV CO index to BSA",
        headerName: "LV CO index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular Cardiac Output Index to Body Surface Area"}>
            <span>LV CO index to BSA [L/min/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LV Mass": {
        field: "LV Mass",
        headerName: "LV Mass",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular Mass"}>
            <span>LV Mass [gr]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LV Mass index to BSA": {
        field: "LV Mass index to BSA",
        headerName: "LV Mass index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular Mass Index to Body Surface Area"}>
            <span>LV Mass index to BSA [gr/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LV stroke volume": {
        field: "LV stroke volume",
        headerName: "LV stroke volume",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular Stroke Volume"}>
            <span>LV stroke volume [cc/beat]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LVEDV index to BSA": {
        field: "LVEDV index to BSA",
        headerName: "LVEDV index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Diastolic Volume Divided by Body Surface Area"}>
            <span>LVEDV Index to BSA [cc/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "LVESV index to BSA": {
        field: "LVESV index to BSA",
        headerName: "LVESV index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Left Ventricular End Systolic Volume Divided by Body Surface Area"}>
            <span>LVESV index to BSA [cc/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV CO index to BSA": {
        field: "RV CO index to BSA",
        headerName: "RV CO index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular Cardiac Output Index to Body Surface Area"}>
            <span>RV CO index to BSA [L/min/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV Cardiac Output": {
        field: "RV Cardiac Output",
        headerName: "RV Cardiac Output",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular Cardiac Output"}>
            <span>RV Cardiac Output [L/min]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV EDV index to BSA": {
        field: "RV EDV index to BSA",
        headerName: "RV EDV index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Diastolic Volume Divided by Body Surface Area"}>
            <span>RV EDV index to BSA [gr/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV ESV index to BSA": {
        field: "RV ESV index to BSA",
        headerName: "RV ESV index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular End Systolic Volume Divided by Body Surface Area "}>
            <span>RV ESV index to BSA [gr/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV Mass": {
        field: "RV Mass",
        headerName: "RV Mass",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular Mass"}>
            <span>RV Mass [gr]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV Mass index to BSA": {
        field: "RV Mass index to BSA",
        headerName: "RV Mass index to BSA",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular Mass Index to Body Surface Area"}>
            <span>RV Mass index to BSA [gr/m2]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "RV stroke volume": {
        field: "RV stroke volume",
        headerName: "RV stroke volume",
        editable: true,
        renderHeader: () => (
            <LightTooltip placement="right-start" title={"Right Ventricular Stroke Volume"}>
            <span>RV stroke volume [cc/beat]</span>
            </LightTooltip>
        ),
        width: 200
    },
    "note": {
        field: "note",
        headerName: "Notes",
        editable: true,
        width: 500
    },
    "Height": {
        field: "Height",
        headerName: "Height [cm]",
        editable: true,
        width: 200
    },
    "Weight": {
        field: "Weight",
        headerName: "Weight [kg]",
        editable: true,
        width: 200
    },
    "procedure": {
        field: "procedure",
        editable: true,
        headerName: "procedure",
        renderHeader: () => (
            <LightTooltip placement="right-start" >
            <span>Procedure</span>
            </LightTooltip>
        ),
        width: 200
    },
    "File Attachments": {
        field: "File Attachments",
        headerName: "File Attachments (count)",
        editable: true,
        width: 200
    }
};

export default table_columns