
function WebLoader() {
  return (
    <>
      <h1 style={{alignContent:"center"}}>
        404 Not Found
      </h1>
      {/*removeTokens()*/}
    </>
  )
}

export default WebLoader;
