import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { Alert } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@material-ui/core/Box";
import './about.css';

export default function FormDialog() {
    const [open, setOpen] = React.useState(false);
    const [fullName, setFullName] = React.useState('');
    const [institution, setInstitution] = React.useState('');
    const [email, setEmail] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const endpoint = "https://6k07jq8ahl.execute-api.us-west-1.amazonaws.com/default/newSendContactEmailJS"
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if (fullName === "") {
            alert("Please provide full name.");
        } else if (email === "") {
            alert("Please provide email address.");
        } else if (institution === "") {
            alert("Please provide institution.");
        } else if (!re.test(email)) {
            alert('Invalid Email Address');
        } else {

            // converts input object to string to send
            const body = JSON.stringify({
                senderName: fullName,
                senderEmail: email,
                message: institution
            });
            const requestOptions = {
                mode: 'no-cors',
                method: "POST",
                body
            };

            // post request takes input and passes to api gateway /lambda_function_name
            fetch(endpoint, requestOptions)
            //console.log(typeof (body))
            //console.log(body);
            alert("Your request has been submitted.")
            handleClose();

        }

    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFullName("");
        setInstitution("");
        setEmail("")
    };

    return (
        <div>
            <ul className='nav navbar-nav navbar-right'>
                <li>
                    <a style={{cursor: 'pointer'}} onClick={handleClickOpen}>Sign Up</a>
                </li>
            </ul>

            <Dialog open={open} fullWidth >
                <Box noValidate autoComplete="off" component="form">

                    <DialogTitle><h2>Request Sign Up</h2></DialogTitle>
                    <DialogContent >
                        <DialogContentText>
                            <h4>
                                Please contact Jeff Omens at UCSD (jomens@ucsd.edu) for more information on this database and obtaining access via a Data Use Agreement with UCSD.
                            </h4>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" size="medium" onClick={handleClose}><p>Close</p></Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
}
