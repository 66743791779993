/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI React
 *
 * File description: Contains List of available filter categories as a series of dropdown menus
 */

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';



const AgeFilter = (props) => {
  const AgeTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#009be5',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009be5',
      },
      '& .MuiInputBase-input': {
        backgroundColor: 'white',
        borderRadius: 8
      },
    },
  });

  return (
    <form onSubmit={props.handleAgeSubmit} >
      <Grid sx={{width: 'fit-content', marginBottom: 3}} container spacing={2} alignItems="center">
        <Grid sx={{width: 'fit-content'}} item xs>
          <AgeTextField
            required
            hiddenLabel
            placeholder="Ex: 19"
            variant="outlined"
            label='From'
            id='from'
            name='from'
            style={{width: 60, marginTop: 6, marginLeft: 22}}
            inputProps={{ maxLength: 3, inputMode: 'numeric', pattern: '^([0-9]|([1-9][0-9])|100)$' }}
          />
        </Grid>
        <Typography sx={{fontSize: 16, color: 'white', paddingLeft: '16px', marginTop: 2.2}}>-</Typography>
        <Grid sx={{width: 'fit-content'}}  item xs>
          <AgeTextField
            required
            hiddenLabel
            placeholder="Ex: 22"
            variant="outlined"
            label='To'
            name='to'
            id="to"
            style={{width: 60, marginTop: 6, }}
            inputProps={{ maxLength: 3, inputMode: 'numeric', pattern: '^([0-9]|([1-9][0-9])|100)$' }}
          />
        </Grid>

        <Grid sx={{width: 'fit-content'}} item xs>
          <Button type='Submit' sx={{ backgroundColor: '#009be5', "&:hover": {backgroundColor: '#009be5'}, padding: '0px 6px', margin: '5px 5px', width: '110px'}}>
            <Typography sx={{ }} style={{textTransform: 'none'}} color="secondary" variant="p">
              Add Filter
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default AgeFilter;