/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI React
 *
 * File description: Contains List of available filter categories as a series of dropdown menus
 */

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';



const CustomIDsListFilter = (props) => {
  const CustomIDsListTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#009be5',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009be5',
      },
      '& .MuiInputBase-input': {
        backgroundColor: 'white',
        borderRadius: 8
      },
    },
  });

  return (
    <form onSubmit={props.handleCustomIDsSubmit} >
      <Grid sx={{width: 'fit-content', marginBottom: 3}} container spacing={2} alignItems="center">
        <Grid sx={{width: 'fit-content'}} item xs={12}>
          <CustomIDsListTextField
            required
            hiddenLabel
            defaultValue={props.customIDs.join(", ")}
            variant="outlined"
            label='Custom ID List'
            id='Custom ID List'
            name='Custom ID List'
            style={{width: '100%', maxWidth: 250, marginTop: 6, marginLeft: 22}}
          />
        </Grid>
        <Grid sx={{width: 'fit-content'}} item xs>
          <Button type='Submit' sx={{ backgroundColor: '#009be5', "&:hover": {backgroundColor: '#009be5'}, padding: '0px 6px', margin: '5px 5px', width: '110px'}}>
            <Typography sx={{ }} style={{textTransform: 'none'}} color="secondary" variant="p">
              Add Filter
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CustomIDsListFilter;