import React, {useState, useEffect} from "react"
import { urls } from "../constants";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const StudiesInS3 = (props) => {
    const [linkedID, setLinkedID] = useState([])
    const [unlinkedID, setUnlinkedID] = useState([])
    const [loading, setLoading] = useState(true)

    const base_url = urls.base_url

    // Fetch Linked Studies
    // Response format: ["CHD0000001", "CHD0000101", "CHD0000102"]
    const fetchLinkedID = async () => {
        fetch(`${base_url}/identify_studies?matched=True`, { headers: { "Authorization": props.authToken } })
        .then((response) => response.json())
        .then((data) => {
        if(data.message === "The incoming token has expired") {
            props.logoutCallback(true);
        }

        setLinkedID(data)

        })
        .catch(() => {
        props.logoutCallback(true);
        });
    }

    // Fetch Unlinked Studies
    // Response format: [["CHD3568301", "CHD35683", ["15y-5m", "16y"]], ["CHD9925303", "CHD99253", ["10y"]], ["CHD0011101", "CHD00111", []]]
    const fetchUnlinkedID = async () => {
        fetch(`${base_url}/identify_studies?matched=False`, { headers: { "Authorization": props.authToken } })
        .then((response) => response.json())
        .then((data) => {
        if(data.message === "The incoming token has expired") {
            props.logoutCallback(true);
        }

        setUnlinkedID(data)
        setLoading(false)

        })
        .catch(() => {
        props.logoutCallback(true);
        });
    }

    // Call both functions above
    const fetchID = async () => {
        try {
            await fetchLinkedID()
            await fetchUnlinkedID()

        } catch (error) {
            props.logoutCallback(true);
        }
    }

    useEffect(() => {
        fetchID()
    }, [])
    
    return(
        <section className="container">
            {loading ? 
            <CircularProgress size={100}/> : 
            <>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 600 }}>
                    Linked Study IDs
                </Typography>
                <ul style={{listStyle: 'Disc', marginLeft: '2vw' }}>
                    {linkedID.map((data) => {
                        return <li key={data} style={{fontSize: 16, marginBottom: 2}}>{data}</li>
                    })}
                </ul>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 600 }}>
                    Unlinked Study IDs
                </Typography>
                <ul style={{listStyle: 'Disc', marginLeft: '2vw' }}>
                    {unlinkedID.map((data) => {
                        return <li key={data[0]} style={{fontSize: 16, marginBottom: 2}}>{data[0]}</li>
                    })}
                </ul>
            </>
        }
        </section>
    );
}

export default StudiesInS3