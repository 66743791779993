/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI React
 *
 * File description: Contains List of available filter categories as a series of dropdown menus
 */

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MuiListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';
import AgeFilter from './AgeFilter';
import CustomIDsListFilter from './CustomIDsListFilter';

const FilterList = (props) => {
  const [open, setOpen] = React.useState("");
  
  const handleClick = (id) => {
    if(id === open) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const ListItem = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "rgb(8, 22, 39, 0.95)",
      },
      backgroundColor: "#081627"
    },
    selected: {}
  })(MuiListItem);

  const handleChecked = (key, filter) => {
    return props.filters["filters"][key]?.includes(filter) ?? false;
  }

  return (
    <Box sx={{ marginBottom: 3 }}>
      {props.filterOptions.map( ({ id: key, children: filterList }) => (
        <List sx={{ paddingTop: 0, paddingBottom: 0, width: '100%', maxWidth: 325, bgcolor: 'background.paper' }} >
          <ListItem button onClick={() => {handleClick(key)}} >
              <ListItemText sx={{ color: 'white', marginLeft: 1,}} primary= {<Typography sx={{fontSize: 16}}>{key}</Typography>}  />
              {open === key ? <ExpandLess sx={{ color: 'white', fontSize: 30}}/> : <ExpandMore sx={{ color: 'white', fontSize: 30}}/>}
          </ListItem>
          <Collapse in={open === key} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {filterList.map( ({ id: filter }) => (
                  <ListItem button onClick={() => {props.handleSelectedFilter(key, filter)}} disablePadding id={filter} key={filter}>
                    <Checkbox checked={handleChecked(key, filter)}
                      sx={{ color: 'white',}}>
                    </Checkbox>
                    <ListItemText sx={{ color: 'white',}} primary= {<Typography sx={{fontSize: 12}}>{filter}</Typography>} />
                  </ListItem>
              ))}
            </List>
          </Collapse>
          <Divider variant="middle" sx={{ marginTop: '1px', }}/>
        </List>
      ))}

      <List sx={{ paddingTop: 0, paddingBottom: 0, width: '100%', maxWidth: 325, bgcolor: 'background.paper' }} >
        <ListItem button onClick={() => {handleClick('Age')}} >
            <ListItemText sx={{ color: 'white', marginLeft: 1,}} primary= {<Typography sx={{fontSize: 16}}>Age</Typography>}  />
            {open === 'Age' ? <ExpandLess sx={{ color: 'white', fontSize: 30}}/> : <ExpandMore sx={{ color: 'white', fontSize: 30}}/>}
        </ListItem>
        <Collapse style={{backgroundColor:'#081627'}} in={open === 'Age'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemText primary= {<Typography sx={{fontSize: 12, color: 'white', marginTop: 2, marginLeft: 3}}>Enter Age Range:</Typography>}></ListItemText>
            <AgeFilter
              handleAgeSubmit={props.handleAgeSubmit}
            ></AgeFilter>
          </List>
        </Collapse>
        <Divider variant="middle" sx={{ marginTop: '1px' }}/>
      </List>
      <List sx={{ paddingTop: 0, paddingBottom: 0, width: '100%', maxWidth: 325, bgcolor: 'background.paper' }} >
        <ListItem button onClick={() => {handleClick('Custom IDs List')}} >
            <ListItemText sx={{ color: 'white', marginLeft: 1,}} primary= {<Typography sx={{fontSize: 16}}>Custom IDs List</Typography>}  />
            {open === 'Custom IDs List' ? <ExpandLess sx={{ color: 'white', fontSize: 30}}/> : <ExpandMore sx={{ color: 'white', fontSize: 30}}/>}
        </ListItem>
        <Collapse style={{backgroundColor:'#081627'}} in={open === 'Custom IDs List'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemText primary= {<Typography sx={{fontSize: 12, color: 'white', marginTop: 2, marginLeft: 3}}>Enter Custom IDs:</Typography>}></ListItemText>
            <CustomIDsListFilter
              customIDs={props.filters["customids"] ?? []}
              handleCustomIDsSubmit={props.handleCustomIDsSubmit}
            ></CustomIDsListFilter>
          </List>
        </Collapse>
        <Divider variant="middle" sx={{ marginTop: '1px' }}/>
      </List>
    </Box>
  );
}

export default FilterList;