import React from "react";
import ReactLoading from 'react-loading';
import { useState, useEffect } from 'react';
import { Navigation } from "../navigation";
import { Link } from 'react-router-dom';
import { urls } from "../constants";

import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

export const StudyMarkdown = (source, state, dispath) => {
    // components prop of Markdown allows us to use our own custom components or html elements
    // and so this is the best way to stylize elements within the markdown.
    return <Markdown
        components={{
            p(props) {
                const {node, ...rest} = props;
                return <p className="about_text fs-4" {...rest}/>;
            },
            li(props) {
                const {node, ...rest} = props;
                return <li className="about_text fs-4 ms-lg-5" {...rest}/>;
            },
            ul(props) {
                const {node, ...rest} = props;
                return <ul className="study-summary-ul" {...rest}/>;
            },
            ol(props) {
                const {node, ...rest} = props;
                return <ol className="study-summary-ol" {...rest}/>;
            },
            summary(props) {
                const {node, ...rest} = props;
                return <summary className="study-summary-dropdown" {...rest}/>;
            }
        }}
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}>
        {source}
    </Markdown>
}

const StudySummary = (props) => {
    let [studyMarkdown, setStudyMarkdown] = useState("");

    useEffect(() => {
        let base_study_summary_url = `${urls.base_url}/study_summary`;
        let study_summary_url = base_study_summary_url + `/${props.study}`

        fetch(study_summary_url, {})
        .then(response => response.json())
        .then(data => {
            setStudyMarkdown(data);
        }).catch(err=>{
            setStudyMarkdown("Couldn't find study");
            console.log("Failed to fetch study summary:", err);
        }, [])
    });

    return (
        <div className="landing_page">
            <Navigation></Navigation>
            {studyMarkdown ? StudyMarkdown(studyMarkdown, null, null) : <ReactLoading/>}
            <Link to="/"><button className="study_summary_button about_text mt-lg-2">Back to Home</button></Link>
        </div>
    );
}

export default StudySummary 