/*
 * Contributors: Fred Lionetti
 *
 * Libraries used: MUI React
 *
 * File description: Creates a box for each patient with a list of studies they can connect to
 */

import { useEffect, useRef, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {urls} from "./constants";
const base_url_upload_tab = urls.base_url_upload_tab;


/**
* Contains function that send PUT request to AWS to save studies for
* a patient
*
* @params key: Specific Key for this component, updateTable: call to updatetable function, patient_id: ID of current patient, 
  authToken: current users authorization token, potential_studies: number of studies,
* @return renders individual patient with select dropdown of available studies
*/
export function IdentifyStudy(props) {
    const[confirmAlert, setconfirmAlert] = useState(0);
    let patient_id = props.patient_id;
    let age = props.age;
    let potential_studies = props.potential_studies;
    //console.log("potential_studies:", potential_studies.length);
    //console.log("prop:", props.length);
    const [selectedStudy, setSelectedStudy] = useState(potential_studies[0]);

    const handleChange = (event) => {
        setSelectedStudy(event.target.value);
    };

    const handleConfirm = (event) => {
        //console.log("you confirmed:", patient_id, age, selectedStudy);
        props.setLoading(true);

        const data = { 'age': age, 'patient_id': patient_id, 'field_value':selectedStudy, 'table_name':"CMR", 'field_name':"Study"};

        //console.log("props.authToken:", props.authToken);
        let headers = {"Authorization":props.authToken, 'Content-Type': 'application/json'}
        let url = `${base_url_upload_tab}`;
        //console.log("updating table with data:", data);
        fetch(url, {method: 'PUT', headers: headers, body: JSON.stringify(data)})
            .then(response => response.json())
            .then(d => {
                //console.log('Success:', d);
                props.updateTable();
            }).catch((error) => {
                console.error('Error:', error);
            });
        setconfirmAlert(1);
        //console.log('setconfirm', confirmAlert);
    }

    return (
        <>
        {
            potential_studies.length !== 0
            ? <>
            <Grid container spacing={2}>
                <Grid item><p>Study <b>{patient_id}</b> corresponds to patient <b>{age}</b> </p>
                </Grid>
                <Grid item>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedStudy} label="Study" onChange={handleChange}>
                    {potential_studies.map((value) => (
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}
                </Select>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>Confirm</Button>
                </Grid>
            </Grid>
            </>
            :<>
            <Grid container spacing={2}>
            </Grid>
            </>
        }
        </>
      );
}
//<Grid item><p>Study <b>{patient_id}</b> has no CMR entry for patient <b>{age}</b>  to link</p></Grid>
  
// this is the code that would go between the following code below. removed because it was moved to missing data           
/*
 <Grid container spacing={2}>
                
            </Grid>
*/
