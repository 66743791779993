import { TextField } from "@material-ui/core";
import React from "react";
import ReactLoading from 'react-loading';
const StudyDownload = (props) => {


    return (
        props.downloadLoad === "<loading>"
        ? <>
        <p style={{marginTop:-30}}>Enter patient ID here. All study folders for this patient will download into a zipped file.</p>
        <TextField
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }} 
                    label="Enter a single patient ID (CHDXXXXX) here, and then press <enter>" variant="outlined" 
                    default={props.downloadKeyPress}
                    onKeyDown={props.downloadKeyPress} style = {{width: 1000}}/>
        <p style={{marginTop:10}}><b>Note:</b> Study folders will take a few seconds to begin download, and downloading large numbers of files can be slow.</p>
        <p>Downloading please wait...</p>
            <div style={{display: 'flex',  justifyContent:'left', alignItems:'center'}}>
            <ReactLoading type="spin" color="#009be5" height={667} width={375} />
            </div>
        </>
        : props.downloadLoad === "<done>"
        ? <>
        <p style={{marginTop:-30}}>Enter patient ID here. All study folders for this patient will download into a zipped file.</p>
        <TextField
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }} 
                    label="Enter a single patient ID (CHDXXXXX) here, and then press <enter>." variant="outlined" 
                    default={props.downloadKeyPress}
                    onKeyDown={props.downloadKeyPress} style = {{width: 1000}}/>
        <p style={{marginTop:10}}><b>Note:</b> Study folders will take a few seconds to begin download, and downloading large numbers of files can be slow.</p>
        </>
        :<>
        </>
    );
}

export default StudyDownload 