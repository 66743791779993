/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI React
 *
 * File description: Contains react element for side navigation bar 
 */

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ExitToApp } from '@material-ui/icons';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Link } from 'react-router-dom';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const getListItem = (ID) => {
  return document.getElementById(ID);
}

const Navigator = (props) => {
  const { ...other } = props;


  /* 
  * Tabs are dynamically created from the below categories and category arrays
  * new tabs can be added by creating a new entry in the tab group
  * however a few things must also be changed 
  * 1. Go the handleLinking function in App.js and create a new case
  *    making sure to give it a unique number for the setNavTabs variable
  * 2. In Paperbase.js follow the format to add a new condition for your page 
  *    so when navTabs = your number it will render your page.
  */
  const categoryTables = {
    id: 'Tables',
    children: [
      { id: 'Patients',active: false, func: props.handleLinking, path: "/Database"},
      { id: 'Single Patient',active: false, func: props.handleLinking, path: "single_patient"},
      { id: 'Single Study', active: true,  func: props.handleLinking, path: "single_study"},
      { id: 'CMR',active: false, func: props.handleLinking, path: "CMR"},
      { id: 'ECHO',active: false, func: props.handleLinking, path: "ECHO"},
      { id: 'CATH',active: false, func: props.handleLinking, path: "CATH"},
      { id: 'ECG',active: false, func: props.handleLinking, path: "ECG"},
      { id: 'CPET',active: false, func: props.handleLinking, path: "CPET" },
      { id: 'Procedure',active: false, func: props.handleLinking, path: "Procedure"},
      { id: 'Clinical Notes',active: false, func: props.handleLinking, path: "Clinical_Notes"},
    ],
  };
  const categoryUpload = {
    id: 'Upload',
    children: [
      { id: 'CSV Tables',active: false, func: props.handleLinking, path: "CSV_upload" },
      { id: 'Study Folders',active: false, func: props.handleLinking, path: "Study_upload" },
      { id: "Clinical Notes Attachments", active: false, func: props.handleLinking, path: "attachment_upload"}
    ],
  };
  const categoryDownload = {
    id: 'Download',
    children: [
      { id: 'CSV Templates', active: false, func: props.handleLinking, path: "CSV_templates" },
      { id: 'Study Folders ', active: false, func: props.handleLinking, path: "Study_download" },
      { id: 'Clinical Notes Attachments ', active: false, func: props.handleLinking, path: "attachment_download"},
    ],
  };
  const categoryAdmin = {
    id: 'Admin',
    children: [
      { id: 'Delete Study Folder', active: false, func: props.handleLinking, path: "delete_study_folder" },
      { id: 'Reset Stage Tables', active: false, func: props.handleLinking, path: "reset_tables" },
      { id: 'Users', active: false, func: props.handleLinking, path: "users" },
      { id: 'Create User', active: false, func: props.handleLinking, path: "create_user" },
      { id: 'Study Summary Instructions', active: false, func: props.handleLinking, path: "study_summary_instructions" },
      { id: 'Study Summary Management', active: false, func: props.handleLinking, path: "study_summary_management" },
      { id: 'Study Summary Page Editor', active: false, func: props.handleLinking, path: "study_summary_page_editor" },
    ],
  };
  const categoryTools = {
    id: 'Tools',
    children: [
      { id: 'Link Study to CMR Entry',active: false, func: props.handleLinking, path: "link_studies" },
      { id: 'CSV Upload Status',active: false, func: props.handleLinking, path: "CSV_upload_status" },
      { id: 'Uploaded Studies', active: false, func: props.handleLinking, path: "uploaded_studies" },
      { id: 'Missing Data', active: false, func: props.handleLinking, path: "missing_data" },
    ]
  }

  const permissionCategoryMappings = [
    { permission: "capchd-upload", category: categoryUpload },
    { permission: "capchd-download", category: categoryDownload },
    { permission: "capchd-admin", category: categoryAdmin },
  ];

  const categoriesToShow = [categoryTables];

  for (var mapping of permissionCategoryMappings) {
    if (props.userGroup.findIndex(element => element === mapping.permission) !== -1) {
      categoriesToShow.push(mapping.category);
    }
  }
  
  //this addition gives category tools the same permission level as upload, change "capchd-upload" to 
  //desired level of permission to change the permission 
  const hasUploadPermissionOrHigher = props.userGroup.some(
    permission => ["capchd-upload"].includes(permission)
  );

  if (hasUploadPermissionOrHigher) {
    categoriesToShow.push(categoryTools);
  }
  
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          CAPCHD
        </ListItem>
        {categoriesToShow.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, func, path }) => (
                <ListItem sx={{px: 0, py: 0}} onClick={(e) => { func(e, childId); props.handleListItemClick(e, childId);}} id={childId} disablePadding key={childId}>
                  <ListItemButton selected={props.selectedIndex === childId} sx={item}>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
            ))}

            <Divider component="li" sx={{ mt: 2, border: "0.5px solid grey" }} />
          </Box>
        ))}

        <ListItem onClick={props.logoutKeyPress} sx={{ py: 2, px: 3, ...item, ...itemCategory }}>
          <ListItemButton id="logout-button">
            <ListItemIcon><ExitToApp style={{ color: 'white'}} /></ListItemIcon>
            <ListItemText sx={{ color: '#fff' }}>Logout</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider component="li" sx={{ mt: 0.25, border: "0.5px solid grey" }} />
      </List>

      <ListItem onClick={props.handleCollapseNav} sx={{ py: 2, px: 3, marginTop: 'auto', ...item, ...itemCategory }}>
        <ListItemButton>
          <ListItemIcon><KeyboardDoubleArrowLeftIcon style={{ fontSize: 25, color: 'white'}} /></ListItemIcon>
          <ListItemText sx={{ color: '#fff' }}>Collapse</ListItemText>
        </ListItemButton>
      </ListItem>
    </Drawer>
  );
}

export default Navigator;
