/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: React-loading, MUI React
 *
 * File description: Central component that combines all pages for the main part
 * of the website. Uses a central theme that overwrites default MUI component CSS and 
 * uses conditional loading to display different pages.
 */

import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Navigator from './Navigator.js';
import NavigatorCollapse from './NavigatorCollapse.js';
import Content from './Content.js';
import Header from './Header.js';
import { UsersTable } from './UsersTable.js';
import {IdentifyStudies} from '../identify_studies';
import TextField from '@material-ui/core/TextField';
import NavigatorPopup from './NavigatorPopup.js';
import Filter from './Filter.js';
import FilterCollapse from './FilterCollapse.js'
import FilterListIcon from '@mui/icons-material/FilterList';
import { UploadStatus } from './UploadStatus.js';
import Grid from '@mui/material/Grid';
import MyTable from '../table.js';
import {tableNames, urls} from "../constants";
import { Select } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { Outlet } from "react-router-dom"

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    secondary: {
      main: "#ffffff"
    }
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: '2.125rem',
      letterSpacing: 0.5,
    },
    body1: {
      fontSize: '2rem',
    }
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1.2rem',
          minWidth: 0,
          maxWidth: 'fit-content',
          color: '#009be5'
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

const Paperbase = ({logoutKeyPress, authToken, headerTitle, setHeaderTitle, handleNavTabs, userGroup, openAlert, setOpenAlert, showFilter, setShowFilter, filters, setFilters, ageError, idError, chdError, ymError, selectedIndex, handleListItemClick, FilterUpdate, selectedCategories, setSelectedCategories, setLoaded, filterOptions, setCollapseNav, collapseNav, handleLinking, category_name, set_category_name, setFiles}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [collapseTable, setCollapseTable] = React.useState(false);
  const [collapseUpload, setCollapseUpload] = React.useState(false);
  const [collapseAdmin, setCollapseAdmin] = React.useState(false);
  const [collapseDownload, setCollapseDownload] = React.useState(false);
  const [collapseTools, setCollapseTools] = React.useState(false);
  const base_url_query_table = urls.base_url_query_table;

  const handleAlertClose = (event) => {
    setOpenAlert(0);
  };
  
  /**
  * Function currently not in use but helps with dynamic CSS
  *
  * @params none
  * @return none
  */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCollapseNav = () => {
    setCollapseNav(true);
  }

  const handleCollapseNavDefault = () => {
    setCollapseNav(false);
    if(showFilter === 2) {
      setShowFilter(1);
    }
  } 

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 1} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="info">
            <AlertTitle  style={{fontSize: 20,  }}>Upload Processing</AlertTitle>
                    Your file has been uploaded and is currently processing. Click the button below to check your status!
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 2} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            Your file has failed to upload. Please try again later.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 3} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              Ignored file with invalid headers.  Make sure to not change headers of downloaded tables.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 4} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            You must select a table before uploading a file.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 5} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              Ignored file with invalid format.  Must start with CHD and be formatted as patient_id/study_id/etc:.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 6} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              "Patient not found. Please try again."
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 7} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              "You must enter a valid patient ID in the form CHDXXXXX where X is a number"
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 8} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              No data exists for this patient. If studies was recently uploaded, please try again in a few minutes.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 9} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              This table has {idError} missing ID(s). Please fix these entries and try uploading again.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 10} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            This table has {chdError} ID(s) not in proper 'CHDXXXXX' format (where X is a number). Please fix these entries and try uploading again.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 11} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            This table has {ageError} missing age(s). Please fix these entries and try uploading again.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 12} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            This table has both {ageError} missing age(s) and {idError} missing ID(s). Please fix these entries and try uploading again.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 13} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            This table has {ymError} age(s) that are invalid. Please fix these entries and try uploading again.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 14} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            Invalid ID format. Must be CHDXXXXX where X is an integer.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 15} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="info">
            <AlertTitle  style={{fontSize: 20,  }}>Upload Processing</AlertTitle>
                    Your study images have been uploaded and are currently being processed. They will be ready for download in a few minutes.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 16} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="warning">
            <AlertTitle  style={{fontSize: 20,  }}>Warning</AlertTitle>
              You must save or cancel editing on current row.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 17} onClose={handleAlertClose} autoHideDuration={5000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              "You must enter a study patient ID in the form CHDXXXXXXX where X is a number"
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 18} onClose={handleAlertClose} autoHideDuration={4000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              "Study ID could not be found, study info was not retrieved"
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 19} onClose={handleAlertClose} autoHideDuration={4000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              "The following study has no information associated with it"
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 20} onClose={handleAlertClose} autoHideDuration={4000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              You cannot upload more than 20,000 files at a time.  Please select a smaller subset of files and try again.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 21} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="info">
            <AlertTitle  style={{fontSize: 20,  }}>Upload Processing</AlertTitle>
                    Analyzing files.  Please wait...
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 22} onClose={handleAlertClose} autoHideDuration={4000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Download Unsuccessful</AlertTitle>
                    Please select a table before downloading
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 23} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
            Invalid Study ID format. Must be CHDXXXXXXX where X is an integer.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 24} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              No data exists for this Study ID. If studies was recently uploaded, please try again in a few minutes.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 25} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              An error occurred when deleting the Study folder. Please try again in a moment.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 26} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="success">
            <AlertTitle  style={{fontSize: 20,  }}>Success</AlertTitle>
              Study folder has been successfully deleted.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 27} onClose={handleAlertClose} autoHideDuration={4000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="info">
            <AlertTitle  style={{fontSize: 20,  }}>Deletion Processing</AlertTitle>
              Deletion started. This may take a few moments.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 28} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="info">
            <AlertTitle  style={{fontSize: 20,  }}>Deletion in Progress</AlertTitle>
              Study folder deletion is currently underway. Please wait until completion before deleting a new study folder.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 29} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="error">
            <AlertTitle  style={{fontSize: 20,  }}>Error</AlertTitle>
              This patient ID doesn't exist in the Clinical Notes table.
          </Alert>
        </Snackbar>

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openAlert === 30} onClose={handleAlertClose} autoHideDuration={6000} >
          <Alert sx={{"& .MuiAlert-icon": { fontSize: 40 }}} style={{fontSize: 16}} severity="info">
            <AlertTitle  style={{fontSize: 20,  }}>Deletion in Progress</AlertTitle>
              File download Unsuccessful. Please try again in a few moment.
          </Alert>
        </Snackbar>

        <CssBaseline />
        {
          collapseNav === false ?
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
            {isSmUp ? null : (
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                logoutKeyPress={logoutKeyPress}
                setHeaderTitle={setHeaderTitle}
                handleCollapseNav={handleCollapseNav}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                userGroup={userGroup}
                handleLinking={handleLinking}
              />
            )}

            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              sx={{ display: { sm: 'block', xs: 'none' } }}
              logoutKeyPress={logoutKeyPress}
              setHeaderTitle={setHeaderTitle}
              handleNavTabs={handleNavTabs}
              handleCollapseNav={handleCollapseNav}
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
              userGroup={userGroup}
              handleLinking={handleLinking}
            />
          </Box>
          :
          <Box
            component="nav"
            sx={{ width: 75, flexShrink: { sm: 0 } }}
            >
            <NavigatorCollapse
              PaperProps={{ style: { width: 75 } }}
              sx={{ display: { sm: 'block', xs: 'none' } }}
              logoutKeyPress={logoutKeyPress}
              setHeaderTitle={setHeaderTitle}
              handleNavTabs={handleNavTabs}
              handleCollapseNav={handleCollapseNav}
              handleCollapseNavDefault={handleCollapseNavDefault}
              setCollapseTable={setCollapseTable}
              setCollapseUpload={setCollapseUpload}
              setCollapseAdmin={setCollapseAdmin}
              setCollapseDownload={setCollapseDownload}
              setCollapseTools={setCollapseTools}
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
              userGroup={userGroup}
              handleLinking={handleLinking}
            />
          </Box>
        }
        {/* Moved logout button to navigation bar*/}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header onDrawerToggle={handleDrawerToggle} headerTitle={headerTitle} />
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
            {
              // FIXME: bottom of popup components are miscolored
              collapseTable === true ?
                  <NavigatorPopup
                  PaperProps={{ style: {width: 200, height: 500, marginLeft: 75, marginTop: "calc(50vh - 178px)" } }}
                  sx={{ display: { sm: 'block', xs: 'none' }, }}
                  logoutKeyPress={logoutKeyPress}
                  setHeaderTitle={setHeaderTitle}
                  handleNavTabs={handleNavTabs}
                  collapseTable={collapseTable}
                  collapseUpload={collapseUpload}
                  collapseDownload={collapseDownload}
                  collapseAdmin={collapseAdmin}
                  collapseTools={collapseTools}
                  setCollapseTable={setCollapseTable}
                  setCollapseUpload={setCollapseUpload}
                  setCollapseAdmin={setCollapseAdmin}
                  setCollapseDownload={setCollapseDownload}
                  setCollapseTools={setCollapseTools}
                  handleListItemClick={handleListItemClick}
                  userGroup={userGroup}
                  handleLinking={handleLinking}
                  />
              : collapseUpload === true ?
              <NavigatorPopup
              PaperProps={{ style: { width: 200, height: 175, marginLeft: 75, marginTop: "calc(50vh - 128px)" } }}
              sx={{ display: { sm: 'block', xs: 'none' }, }}
              logoutKeyPress={logoutKeyPress}
              setHeaderTitle={setHeaderTitle}
              handleNavTabs={handleNavTabs}
              collapseTable={collapseTable}
              collapseUpload={collapseUpload}
              collapseAdmin={collapseAdmin}
              collapseDownload={collapseDownload}
              collapseTools={collapseTools}
              setCollapseTable={setCollapseTable}
              setCollapseUpload={setCollapseUpload}
              setCollapseAdmin={setCollapseAdmin}
              setCollapseDownload={setCollapseDownload}
              setCollapseTools={setCollapseTools}
              handleListItemClick={handleListItemClick}
              userGroup={userGroup}
              handleLinking={handleLinking}
              />
              : collapseAdmin === true ?
              <NavigatorPopup
              PaperProps={{ style: { width: 200, height: 205, marginLeft: 75, marginTop: "calc(50vh + 22px)" } }}
              sx={{ display: { sm: 'block', xs: 'none' }, }}
              logoutKeyPress={logoutKeyPress}
              setHeaderTitle={setHeaderTitle}
              handleNavTabs={handleNavTabs}
              collapseTable={collapseTable}
              collapseUpload={collapseUpload}
              collapseAdmin={collapseAdmin}
              collapseDownload={collapseDownload}
              collapseTools={collapseTools}
              setCollapseTable={setCollapseTable}
              setCollapseUpload={setCollapseUpload}
              setCollapseAdmin={setCollapseAdmin}
              setCollapseDownload={setCollapseDownload}
              setCollapseTools={setCollapseTools}
              handleListItemClick={handleListItemClick}
              userGroup={userGroup}
              handleLinking={handleLinking}
              />
              : collapseDownload === true ?
              <NavigatorPopup
              PaperProps={{ style: { width: 200, height: 175, marginLeft: 75, marginTop: "calc(50vh - 78px)" } }}
              sx={{ display: { sm: 'block', xs: 'none' }, }}
              logoutKeyPress={logoutKeyPress}
              setHeaderTitle={setHeaderTitle}
              handleNavTabs={handleNavTabs}
              collapseTable={collapseTable}
              collapseUpload={collapseUpload}
              collapseAdmin={collapseAdmin}
              collapseDownload={collapseDownload}
              collapseTools={collapseTools}
              setCollapseTable={setCollapseTable}
              setCollapseUpload={setCollapseUpload}
              setCollapseAdmin={setCollapseAdmin}
              setCollapseDownload={setCollapseDownload}
              setCollapseTools={setCollapseTools}
              handleListItemClick={handleListItemClick}
              userGroup={userGroup}
              handleLinking={handleLinking}
              />
              : collapseTools === true ?
              <NavigatorPopup
              PaperProps={{ style: { width: 200, height: 230, marginLeft: 75, marginTop: "calc(50vh - 28px)" } }}
              sx={{ display: { sm: 'block', xs: 'none' }, }}
              logoutKeyPress={logoutKeyPress}
              setHeaderTitle={setHeaderTitle}
              handleNavTabs={handleNavTabs}
              collapseTable={collapseTable}
              collapseUpload={collapseUpload}
              collapseAdmin={collapseAdmin}
              collapseDownload={collapseDownload}
              collapseTools={collapseTools}
              setCollapseTable={setCollapseTable}
              setCollapseUpload={setCollapseUpload}
              setCollapseAdmin={setCollapseAdmin}
              setCollapseDownload={setCollapseDownload}
              setCollapseTools={setCollapseTools}
              handleListItemClick={handleListItemClick}
              userGroup={userGroup}
              handleLinking={handleLinking}
              />
              :
              <>
              </>
            }
            {
              showFilter === 2 &&
              <Filter
               PaperProps={{ style: { width: 325 } }}
               sx={{ display: { sm: 'block', xs: 'none' } }}
               filterOptions={filterOptions}
               filters={filters}
               setFilters={setFilters}
               showFilter={showFilter}
               setShowFilter={setShowFilter}
               FilterUpdate={FilterUpdate}
               selectedCategories={selectedCategories}
               setSelectedCategories={setSelectedCategories}
               setLoaded={setLoaded}
               authToken={authToken}
              />
            }
            {
              <Outlet />
            }
          </Box>
        </Box>
        {
            showFilter === 2 ?
                    <Box style={{ width: 300 }}>
                    </Box>
                :
                <>
                </>
        }
      </Box>
    </ThemeProvider>
  );
}

export default Paperbase;