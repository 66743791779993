

import { useEffect, useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {urls} from '../constants';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
const query_url = urls.base_url_query_table;

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 1500,
  },
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export function UploadStatus(props) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState([true])
    const [pageSize, setPageSize] = useState(10);
    const [checked, setChecked] = useState([false])
    const classes = useStyles();
    
    const handleChange = () => {
      setChecked(!checked);
      if(checked === false){
        updateTableAll(props.table_name);
      }
      else{
        updateTable(props.table_name);
      }
    };


    function updateTableAll(table_name) {
      setLoading(true);
      let url = `${query_url}?cmd=get_upload_statuses`;
      fetch(url, {headers:{"Authorization":props.authToken}})
      .then(response => response.json())
      .then(data => {
         
          //console.log("got data:", data);
          //console.log("row length: ", Object.keys(data).length)
          //console.log("username: ", props.userName);

          let defaultColumns = [];
          defaultColumns.push({
            field: "File Name",
            headerName: "File Name",
            editable: false,
            width: 300,
            align: "left",
            headerAlign: "left"
          });
          defaultColumns.push({
            field: "Time Received",
            headerName: "Time Received",
            type: 'dateTime',
            editable: false,
            width: 300,
            align: "left",
            headerAlign: "left"
          });
          defaultColumns.push({
            field: "Status",
            headerName: "Status",
            editable: false,
            width: 800,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => (
              <LightTooltip placement="right-start" title={params.value}>
                <span>{params.value}</span>
              </LightTooltip>
            )
          });

          setColumns(defaultColumns);

          let keys = Object.keys(data);
          let filesList = [];
          let filename;
          let time;
          let message;
          for(let i = 0; i < keys.length; i++) {
            filename = keys[i];
            time = data[keys[i]][0];
            time = time * 1000
            time = new Date(time);
            message = data[keys[i]][1];
            filesList.push({
              "File Name": filename,
              "Time Received": time,
              "Status": message
            })
          }
          filesList.sort((date1, date2) => date2['Time Received'] - date1['Time Received']);
          setRows(filesList);

          setLoading(false);
      }).catch(err=>{
        //console.log("Failed to fetch!", props.logoutCallback);
        props.logoutCallback(true);
    });
  }



    function updateTable(table_name) {
      setLoading(true);
        let url = `${query_url}?cmd=get_upload_statuses`;
        fetch(url, {headers:{"Authorization":props.authToken}})
        .then(response => response.json())
        .then(data => {
           
            //console.log("got data:", data);
            //console.log("row length: ", Object.keys(data).length)
            //console.log("username: ", props.userName);

            let defaultColumns = [];
            defaultColumns.push({
              field: "File Name",
              headerName: "File Name",
              editable: false,
              width: 300,
              align: "left",
              headerAlign: "left"
            });
            defaultColumns.push({
              field: "Time Received",
              headerName: "Time Received",
              type: 'dateTime',
              editable: false,
              width: 300,
              align: "left",
              headerAlign: "left"
            });
            defaultColumns.push({
              field: "Status",
              headerName: "Status",
              editable: false,
              width: 800,
              align: "left",
              headerAlign: "left",
              renderCell: (params) => (
                <LightTooltip placement="right-start" title={params.value}>
                  <span>{params.value}</span>
                </LightTooltip>
              )
            });

            setColumns(defaultColumns);

            let keys = Object.keys(data);
            let filesList = [];
            let filename;
            let time;
            let message;
            for(let i = 0; i < keys.length; i++) {
              filename = keys[i];
              time = data[keys[i]][0];
              time = time * 1000
              time = new Date(time);
              message = data[keys[i]][1];
              if(filename.startsWith(props.userName)){
                filesList.push({
                  "File Name": filename,
                  "Time Received": time,
                  "Status": message
                })
              }
            }
            filesList.sort((date1, date2) => date2['Time Received'] - date1['Time Received']);
            setRows(filesList);

            setLoading(false);
        }).catch(err=>{
          //console.log("Failed to fetch!", props.logoutCallback);
          props.logoutCallback(true);
      });
    }

    useEffect(() => {
      //console.log("Loaded app!:");
      updateTable(props.table_name)
      setChecked(false);
    }, [])

      return (
        <>
          {
            loading 
            ? <>
            <CircularProgress size={100} />
            </>
            : rows.length === 0
            ? <>
            <FormGroup>
              <FormControlLabel style={{pointerEvents:"none"}} control={<Switch checked={checked} onChange={handleChange} style={{pointerEvents:"auto"}}/>} label={<span style={{ fontSize: '1.5rem' }}>View All Uploads</span>} />
            </FormGroup>
            <h4>There are no recent files uploaded</h4>
            </>
            :
            <>
            <FormGroup>
              <FormControlLabel style={{pointerEvents:"none"}} control={<Switch checked={checked} onChange={handleChange} style={{pointerEvents:"auto"}}/>} label={<span style={{ fontSize: '1.5rem' }}>View All Uploads</span>} />
            </FormGroup>
            <div style={{ height: "100%", width: "100%", marginBottom: 30 }} className={classes.root}>
              <div >
                <DataGridPro
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50]}
                  pagination
                  rows={rows}
                  columns={columns}
                  //onCellDoubleClick={onCellDoubleClick}
                  getRowId={(row) => row["File Name"]}
                />
              </div>
              </div>
            </>
          }
        </>
      );
  }
  
