/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI React
 *
 * File description: Collapsed version of the navigation bar
 */

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';


const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const getListItem = (ID) => {
  return document.getElementById(ID);
}

const NavigatorPopup = (props) => {
  const { ...other } = props;
  const [open, setOpen] = React.useState(true);

  let userInUploadGroup = props.userGroup.findIndex(element => element === "capchd-upload") !== -1;
  let userInAdminGroup = props.userGroup.findIndex(element => element === "capchd-admin") !== -1;
  let userInDownloadGroup = props.userGroup.findIndex(element => element === "capchd-download") !== -1;

  const handleOpen = () => {
    setOpen(!open);
  };

  /* 
  * Tabs are dynamically created from this array
  * new tabs can be added by creating a new entry
  * however a few things must also be changed 
  * 1. Go the handleLinking function in App.js and create a new case
  *    making sure to give it a unique number for the setNavTabs variable
  * 2. In Paperbase.js follow the format to add a new condition for your page 
  *    so when navTabs = your number it will render your page.
  */ 
  const tableList = [
    {
      id: 'Tables',
      children: [
        { id: 'Patients',active: false, func: props.handleLinking, path: "/Database"},
        { id: 'Single Patient',active: false, func: props.handleLinking, path: "single_patient"},
        { id: 'Single Study', active: false, func: props.handleLinking, path: "single_study"},
        { id: 'CMR',active: false, func: props.handleLinking, path: "CMR"},
        { id: 'ECHO',active: false, func: props.handleLinking, path: "ECHO"},
        { id: 'CATH',active: false, func: props.handleLinking, path: "CATH"},
        { id: 'ECG',active: false, func: props.handleLinking, path: "ECG"},
        { id: 'CPET',active: false, func: props.handleLinking, path: "CPET" },
        { id: 'Procedure',active: false, func: props.handleLinking, path: "Procedure"},
        { id: 'Clinical Notes',active: false, func: props.handleLinking, path: "Clinical_Notes"},
      ],
    },
  ];
  const uploadList = [
    {
      id: 'Upload',
      children: [
        { id: 'CSV Tables',active: false, func: props.handleLinking, path: "CSV_upload" },
        { id: 'Study Folders',active: false, func: props.handleLinking, path: "Study_upload" },
        { id: "Clinical Notes Attachments", active: false, func: props.handleLinking, path: "attachment_upload"}
      ],
    },
  ];
  const downloadList = [
    {
      id: 'Download',
      children: [
        { id: 'CSV Templates', active: false, func: props.handleLinking, path: "CSV_templates" },
        { id: 'Study Folders ', active: false, func: props.handleLinking, path: "Study_download" },
        { id: 'Clinical Notes Attachments ', active: false, func: props.handleLinking, path: "attachment_download"},
      ],
    },
  ];
  const adminList = [
    {
      id: 'Admin',
      children: [
        { id: 'Delete Study Folder', active: false, func: props.handleLinking, path: "delete_study_folder" },
        { id: 'Reset Stage Tables', active: false, func: props.handleLinking, path: "reset_tables" },
        { id: 'Users', active: false, func: props.handleLinking, path: "users" },
        { id: 'Create User', active: false, func: props.handleLinking, path: "create_user" },
        { id: 'Study Summary Instructions', active: false, func: props.handleLinking, path: "study_summary_instructions" },
        { id: 'Study Summary Management', active: false, func: props.handleLinking, path: "study_summary_management" },
        { id: 'Study Summary Page Editor', active: false, func: props.handleLinking, path: "study_summary_page_editor" },
      ],
    },
  ];
  const toolsList = [
    {
      id: 'Tools',
      children: [
        { id: 'Link Study to CMR Entry',active: false, func: props.handleLinking, path: "link_studies" },
        { id: 'CSV Upload Status',active: false, func: props.handleLinking, path: "CSV_upload_status" },
        { id: 'Uploaded Studies', active: false, func: props.handleLinking, path: "uploaded_studies" },
        { id: 'Missing Data', active: false, func: props.handleLinking, path: "missing_data" },
      ]
    }
  ];

  return (
    props.collapseTable ? <>
    <Drawer onMouseOver={(e) => {props.setCollapseTable(true);}} onMouseLeave={(e) => {props.setCollapseTable(false);}} variant="permanent" {...other}>
      <List sx={{}} disablePadding>
        <Divider component="li" sx={{ border: "0.5px solid grey" }} />
        {tableList.map(({ id, children }) => (
          <Box  key={id} sx={{ bgcolor: '#101F33' }}>
            {children.map(({ id: childId, func, path }) => (
              <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}}  onClick={(e) => { func(e, childId); props.handleListItemClick(e, childId);}} id={childId} disablePadding key={childId}>
                <ListItemButton sx={item}>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box> 
        ))}
      </List>
    </Drawer>
    </>
    : props.collapseUpload && (userInUploadGroup || userInAdminGroup) ? <>
    <Drawer onMouseOver={(e) => {props.setCollapseUpload(true);}} onMouseLeave={(e) => {props.setCollapseUpload(false);}} variant="permanent" {...other}>
      <List sx={{}} disablePadding>
        <Divider component="li" sx={{ border: "0.5px solid grey",}} />
        {uploadList.map(({ id, children, }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            {children.map(({ id: childId, func, path }) => (
              <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onClick={(e) => { func(e, childId); props.handleListItemClick(e, childId);}} id={childId} disablePadding key={childId}>
                <ListItemButton sx={item}>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    </Drawer>
    </>
    : props.collapseDownload && (userInDownloadGroup || userInAdminGroup) ? <>
    <Drawer onMouseOver={(e) => {props.setCollapseDownload(true);}} onMouseLeave={(e) => { props.setCollapseDownload(false);}} variant="permanent" {...other}>
      <List sx={{}} disablePadding>
        <Divider component="li" sx={{ border: "0.5px solid grey",}} />
        {downloadList.map(({ id, children, }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            {children.map(({ id: childId, func, path }) => (
              <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onClick={(e) => {func(e, childId); props.handleListItemClick(e, childId);}} id={childId} disablePadding key={childId}>
                <ListItemButton sx={item}>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    </Drawer>
    </>
    : props.collapseTools ? <>
    <Drawer onMouseOver={(e) => {props.setCollapseTools(true);}} onMouseLeave={(e) => {props.setCollapseTools(false);}} variant="permanent" {...other}>
      <List sx={{}} disablePadding>
        <Divider component="li" sx={{ border: "0.5px solid grey",}} />
        {toolsList.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            {children.map(({ id: childId, func, path }) => (  
              <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onClick={(e) => { func(e, childId); props.handleListItemClick(e, childId);}} id={childId} disablePadding key={childId}>
                <ListItemButton sx={item}>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    </Drawer>
    </> 
    : props.collapseAdmin && userInAdminGroup ? <>
    <Drawer onMouseOver={(e) => {props.setCollapseAdmin(true);}} onMouseLeave={(e) => {props.setCollapseAdmin(false);}} variant="permanent" {...other}>
      <List sx={{}} disablePadding>
        <Divider component="li" sx={{ border: "0.5px solid grey",}} />
        {adminList.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            {children.map(({ id: childId, func, path }) => (  
              <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onClick={(e) => { func(e, childId); props.handleListItemClick(e, childId);}} id={childId} disablePadding key={childId}>
                <ListItemButton sx={item}>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    </Drawer>
    </>
    : <>
    </>
  );
}

export default NavigatorPopup;