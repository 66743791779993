import { useState } from 'react';
import { urls } from "../constants";
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const base_url_query_table = urls.base_url_query_table;

const ResetStageTables = (props) => {
  const [resetResults, setResetResults] = useState("");
  const [loading, setLoading] = useState(false)

  const reset_stage_tables_url = `${base_url_query_table}?cmd=reset_stage_tables`;

  const resetStageTables = () => {
    setLoading(true);

    fetch(reset_stage_tables_url, { headers: { "Authorization": props.authToken } })
    .then((response) => response.json())
    .then((data) => {
      if(data.message === "The incoming token has expired") {
        props.logoutCallback(true);
      }
      
      setLoading(false);
      setResetResults(data);
    }).catch(() => {
        //console.log("Failed to fetch!", props.logoutCallback);
        props.logoutCallback(true);
    });
  }

  return (
    <Box>
      <Button onClick={() => resetStageTables()} style={{backgroundColor: '#009be5', margin: "5px 5px"}}>
        <Typography style={{textTransform: 'none', fontSize: 12}} color="secondary" variant="p">
          Reset Stage Tables
        </Typography>
      </Button>
      <h4>Results:</h4>
      {
        loading ?
        <CircularProgress size={100} /> 
        : <div dangerouslySetInnerHTML={{__html: resetResults}} /> 
      }
    </Box>
  );
}

export default ResetStageTables;