import { useState } from 'react';
import { urls, validCognitoGroups } from "../constants";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const base_url_user_management = urls.base_url + '/user_management';

const initial_state = {};
for (const group of validCognitoGroups) {
  initial_state[group] = false;
}

const CreateUser = (props) => {
  const [inputUsername, setInputUsername] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [selectedGroups, setSelectedGroups] = useState(initial_state);
  const [results, setResults] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUsernameChange = (event) => {
    setInputUsername(event.target.value);
  }

  const handleEmailChange = (event) => {
    setInputEmail(event.target.value);
  }

  const handleGroupChange = (event) => {
    setSelectedGroups({
      ...selectedGroups,
      [event.target.name]: event.target.checked,
    });
  };

  const create_user = (username, email, groupsObject) => {
    const groups = [];

    for (const key of Object.keys(groupsObject)) {
      if (groupsObject[key]) groups.push(key);
    }
    
    const searchParams = new URLSearchParams({
      cmd: 'create_user',
      username: username,
      email: email,
      groups: groups
    });

    const create_new_user_url = base_url_user_management + '/?' + searchParams;

    fetch(create_new_user_url, { headers: { "Authorization": props.authToken } })
    .then((response) => response.json())
    .then((data) => {
      if(data.message === "The incoming token has expired") {
        props.logoutCallback(true);
      }
      
      setResults(data);

      setLoading(false);
    }).catch(() => {
        props.logoutCallback(true);
    });
  }

  return (
    <section className="container">
      <Typography variant="h2" gutterBottom>
        Create User
      </Typography>
      <Typography variant="body1" gutterBottom>
        Enter all following info and then press the "Create User" button.
        The results of the user creation process will be shown below.
        The user will get an email sent to them to complete the registration process.
      </Typography>
      <Box component="form">
        <FormControl required sx={{ m: 3 }} variant="standard">
          <FormLabel component="legend">Username</FormLabel>
          <TextField id="outlined-basic" label="Username" onChange={handleUsernameChange} variant="outlined" />
          <FormHelperText sx={{ fontSize: 12 }}>Enter a unique username for new user</FormHelperText>
        </FormControl>

        <FormControl required sx={{ m: 3 }} variant="standard">
          <FormLabel component="legend">Email</FormLabel>
          <TextField id="outlined-basic" label="Email" onChange={handleEmailChange} variant="outlined" />
          <FormHelperText sx={{ fontSize: 12 }}>Enter a unique email for new user</FormHelperText>
        </FormControl>

        <FormControl sx={{ m: 3 }} variant="standard">
          <FormLabel component="legend">Permissions</FormLabel>
          <FormGroup>
            {validCognitoGroups.map(groupName => (
              <FormControlLabel
              control={
                <Checkbox checked={selectedGroups[groupName]} onChange={handleGroupChange} name={groupName} />
              }
              label={groupName}
            />
            ))}
          </FormGroup>
          <FormHelperText sx={{ fontSize: 12 }}>Select permissions for the new user</FormHelperText>
        </FormControl>
      </Box>
      <Button onClick={() => create_user(inputUsername, inputEmail, selectedGroups)} style={{backgroundColor: '#009be5', margin: "5px 5px"}}>
          <Typography style={{textTransform: 'none', fontSize: 24}} color="secondary" variant="p">
            Create User
          </Typography>
        </Button>
      <Typography variant="h3" gutterBottom>
        Results
      </Typography>
      {
        loading ?
        <CircularProgress size={100} />
        :<>
         <Typography variant="body1">{results}</Typography>
        </>
      }
    </section>
  );
}

export default CreateUser;