import React from "react"
import './about.css'
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { BrowserRouter as Router, Switch, 
    Route, Redirect,} from "react-router-dom";
import { Link } from 'react-router-dom';
import { urls } from "./constants";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Navigation } from "./navigation";
// import axios from 'axios';
// import { getapi } from './landing_summary'

const StudySummaryComponent = ({studySummary}) => {
    return (
        <Grid item xs="6">
        <div className="mt-lg-5">
            <h3 className="study_header">{studySummary["Header"]}</h3>
            <p className="about_text fs-4">{studySummary["About"]}</p>
            <Link to={`/${studySummary["Link"]}`}><button className="study_button about_text">Read More</button> </Link>
        </div>
        </Grid>
    );
}

export const Landing_Page = (props) => {
    let [table, setTable] = useState(undefined);
    let [studySummaryList, setStudySummaryList] = useState(undefined);

    useEffect(() => {
        let summary_url = `${urls.base_url}/do_get_summary_table`;
        let study_summary_url = `${urls.base_url}/study_summary`;

        if(table === undefined){
            fetch(summary_url, {headers:{}})
            .then(response => response.json())
            .then(data => {
                setTable(data);
                console.log("summary", data)
            }).catch(err=>{
                console.log("Failed to fetch summary:", err);
            }, [])
        }

        if(studySummaryList === undefined){
            fetch(study_summary_url, {headers:{}})
            .then(response => response.json())
            .then(data => {
                setStudySummaryList(data.sort((a, b) => a["ID"] - b["ID"]));
                console.log("study summary table:", data)
            }).catch(err=>{
                console.log("Failed to fetch study summary:", err);
            }, [])
        }
    });

    return (
        <div className="landing_page">
            <Navigation />
            <div className="container-fluid">
                {/* ABOUT SECTION */}
                <div>
                    <div className="row_l">
                        <div className='col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 '>
                            {/* <h2>About</h2> */}
                            <div className="d-flex justify-content-center">
                                <img src="img/about.jpg" className="img-responsive" alt="" />
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8'>
                            <div className="col-12"><h2>About</h2></div>
                            <p className='about_text fs-4'>The Congenital Heart Disease (CHD) Atlas represents MRI data sets, physiologic clinical data, and computational models from adults and children with various congenital heart defects. The data have been acquired from several clinical centers including Rady Children’s Hospital San Diego, UC San Diego Medical Center, and Starship Children’s Hospital in Auckland, New Zealand. The purpose of the study is to create a database of cardiac images and computational models in CHD patients that will enable clinicians and scientists to examine detailed ventricular shape and function in individual patients and compare them statistically against a database of similar CHD patient examinations. The computational models in this database allow for improved quantitative analysis of ventricular remodeling and changes in function to provide optimal management of CHD. The CHD Atlas database is part of the Cardiac Atlas Project (www.cardiacatlas.org), a worldwide project to establish a structural and functional atlas of the heart. Access to the database is subject to a Data Use Agreement between individual users and the owners (i.e. UCSD) of the data sets. Please contact PIs at the Cardiac Mechanics Research Group (cmrg.ucsd.edu) for information on DUAs with UCSD.</p>
                        </div>
                    </div>

                </div>
                {/* OVERVIEW SECTION */}
                <div >
                    <div className="row_l pt-5">
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-7 col-xl-6 px-4 '>
                            <div className="row_l">
                                <h2>Overview of the Database</h2>
                                <p className="about_text fs-4 px-1">The CHD Atlas database separates and organizes coded patient data using a unique patient ID in the format “CHD12345”. Each patient has basic clinical information (Cohort, Sex, Ethnicity, Race), and then specific clinical parameters, measures and clinical files organized by study type (i.e. MRI, Echo, ECG, etc.) and corresponding age. Procedures and other data are also organized by age. MR imaging studies are further organized by a study identifier with an added instance number (e.g. CHD1234501), and these studies can include DICOM images and computational models for these studies. The patient data are organized with the basic structure on the left.</p>
                            </div>
                            <div>
                                <div className='col-xs-12 col-sm-12 col-md-6 col-xl-5'>
                                    <div className="d-flex justify-content-start">
                                        <img src="img/overview_db.jpeg" className="img-responsive  pt-5" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 md-offset-6">
                                    <h3 className="fs-3"> Current Database Summary </h3>
                                    {table != undefined ? (
                                        <p className="about_text fs-4">
                                            Total Number of Patients: { table['Patients'] }<br />
                                            Number of Study Image Sets: { table['Study Image Sets'] } <br />
                                            Ages (range, mean): { table['Age'] }<br />
                                            Total Clinical Exam Sets: { table['Clinical Exam Sets'] }<br />
                                            Largest Disease Cohorts (number of patients):  <br />
                                            <p className = 'fs-4' style={{ marginLeft: '12px'}}>
                                                {table['Main Cohorts'].map(cohort => (
                                                        <> - {cohort[0]} : {cohort[1]} <br /></>
                                                ))}
                                            </p>
                                        </p>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                            <div className="align-self-center">
                                <h3>Database Organization</h3>
                                <img src="img/db_org_placeholder.jpeg" className="img-responsive align-self-stretch " alt="" />
                            </div>
                        </div>
                        <div className="study px-4">
                            <div className="col-12  ">
                                <h3 className="fs-3">Studies</h3>
                                <p className="about_text fs-4">
                                The CHD Atlas is used for a diverse set published and ongoing scientific studies. Patient data used in most of these studies require Data Use Agreements, and other tools and data sets are available from various sources. Please read the descriptions below for more information.
                                </p>
                            </div>
                            <Box flexGrow="1">
                                <Grid container spacing={3}>
                                    {studySummaryList ? studySummaryList.map(studySummary => (
                                        <StudySummaryComponent key={studySummary["ID"]} studySummary={studySummary} />
                                    )) : <></>}
                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}