/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI React
 *
 * File description: Contains react element for side navigation bar 
 */

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FilterList from './FilterList';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CloseIcon from '@mui/icons-material/Close';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};


const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  typography: {
    h4: {
      fontWeight: 300,
      fontSize: 16,
      letterSpacing: 0.5,
    },
    h3: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    p: {
      fontWeight: 300,
      fontSize: 12,
    }
  },
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#ffffff'
    }
  },
  shape: {
    borderRadius: 8,
  },
  radio: {
    '&$checked': {
      color: '#42a5f5'
    },
    checked: {}
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#808080',
          width: '100%',
          height: 0.25,
          margin: 'auto',
          marginTop: 3,
          marginBottom: 0
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        colorSecondary: {
          '&$checked': {
            color: 'green',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: 'white',
          fontSize: 30
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          color: 'white'
        },
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#ccc",
            "&$checked": {
              // Controls checked color for the thumb
              color: "#f2ff00"
            }
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            backgroundColor: "#fff",
            "$checked$checked + &": {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: "#fff"
            }
          }
        }
      }
    }
  }
});

const Filter = (props) => {
  const { ...other } = props;

  const handleSelectedFilter = (key, filter) => {
    let filters = {
      ...props.filters,
      "filters": {
        ...props.filters["filters"],
        [key]: [ ...(props.filters["filters"][key] ?? []) ]
      }
    };
    
    let filtersList = filters["filters"][key];
    let filterIndex = filtersList.indexOf(filter);
    
    if(filterIndex > -1) {
      filtersList.splice(filterIndex, 1);
      props.setFilters(filters);
    } else {
      filtersList.push(filter);
      props.setFilters(filters);
    }
  }

  const handleStudyChange = (e) => {
    props.setFilters({...props.filters, "requirestudies": e.target.checked});
  }

  const handleAgeSubmit = (event) => {
    event.preventDefault();
    let from = parseInt(event.target[0].value);
    let to = parseInt(event.target[2].value);
    if(from >= to) {
      alert("'From' entry must be smaller than 'To' entry");
      return;
    }

    props.setFilters({...props.filters, age:[from, to]});
  };

  const handleCustomIDsSubmit = (event) => {
    event.preventDefault();
    let customIDsString = event.target[0].value;
    const idRegExp = /[C][H][D][0-9][0-9][0-9][0-9][0-9]/g;

    const matchedIDs = customIDsString.match(idRegExp) ?? [];

    if (!matchedIDs.length && customIDsString) alert("No valid IDs found, check format");

    props.setFilters({...props.filters, "customids":matchedIDs});
  }

  const handleClearAll = () => {
    props.setFilters({
      ...props.filters,
      "filters":{},
      "requirestudies": false,
      "age": []
    });
  }

  const handleStudyClose = (e) => {
    props.setFilters({...props.filters, "requirestudies": false});
  }

  const handleAgeClose = () => {
    props.setFilters({...props.filters, "age": []});
  }

  const handleCustomIDsClose = () => {
    props.setFilters({...props.filters, "customids": []});
  }

  const atLeastOneFilter = () => {
    return Object.values(props.filters["filters"]).some(fl => fl?.length) ||
            props.filters["age"]?.length ||
            props.filters["requirestudies"];
  }

  const mapFiltersToDisplay = (key) => {
    let filterValue = props.filters[key];

    if (key === "age" && filterValue?.length) {
      return (
        <Button sx={{ backgroundColor: '#009be5', "&:hover": {backgroundColor: '#009be5'}, padding: '0px 6px', margin: '5px 5px'}}>
          <Typography style={{textTransform: 'none'}} color="secondary" variant="p">
            Ages: {filterValue[0]} - {filterValue[1]}
          </Typography>
          <CloseIcon onClick={() => {handleAgeClose();}}
            style={{fontSize: 16, position: 'relative', bottom: '1px', marginLeft: 8}} htmlColor='#FFFFFF' />
        </Button>
      );
    }

    if (key === "requirestudies" && filterValue) {
      return (
        <Button sx={{ backgroundColor: '#009be5', "&:hover": {backgroundColor: '#009be5'}, padding: '0px 6px', margin: '5px 5px'}}>
          <Typography sx={{ }} style={{textTransform: 'none'}} color="secondary" variant="p">
            Require DICOMs
          </Typography>
          <CloseIcon onClick={() => {handleStudyClose();}}
            style={{fontSize: 16, position: 'relative', bottom: '1px', marginLeft: 8}} htmlColor='#FFFFFF'></CloseIcon>
        </Button>
      );
    }

    if (key === "customids" && filterValue?.length) {
      return (
        <Button sx={{ backgroundColor: '#009be5', "&:hover": {backgroundColor: '#009be5'}, padding: '0px 6px', margin: '5px 5px'}}>
          <Typography sx={{ }} style={{textTransform: 'none'}} color="secondary" variant="p">
            Custom ID List
          </Typography>
          <CloseIcon onClick={() => {handleCustomIDsClose();}}
            style={{fontSize: 16, position: 'relative', bottom: '1px', marginLeft: 8}} htmlColor='#FFFFFF'></CloseIcon>
        </Button>
      );
    }

    return Object.keys(filterValue).map(key => (filterValue[key].map(filter => (
      <Button sx={{ backgroundColor: '#009be5', "&:hover": {backgroundColor: '#009be5'}, padding: '0px 6px', margin: '5px 5px'}}>
        <Typography style={{textTransform: 'none'}} color="secondary" variant="p">
          {filter}
        </Typography>
        <CloseIcon onClick={() => {handleSelectedFilter(key, filter);}}
          style={{fontSize: 16, position: 'relative', bottom: '1px', marginLeft: 8}} htmlColor='#FFFFFF' />
      </Button>
    ))));
  }

  return (
    <ThemeProvider theme={theme}>
      <Drawer style={{overflow: "hidden"}} color='primary' open='true' anchor='right' variant="permanent" {...other}>
        <Box sx={{ marginBottom: 3,  }}>
          <Grid sx={{display: 'flex', flexDirection: 'row',  justifyContent:"flex-end" }} container>
            <Grid item>
              <Button onClick={() => {props.setShowFilter(0);}} style={{textTransform: 'none'}} sx={{paddingTop: 1, paddingRight: 1, width: 'fit-content'}}>
                <Typography sx={{marginRight: 1}} color="secondary" variant="p">
                  Hide Sidebar
                </Typography>
                <CloseIcon  style={{fontSize: 17,}} htmlColor='#FFFFFF'></CloseIcon>
              </Button>
            </Grid>
          </Grid>
          
          <Grid sx={{marginTop: 10, marginLeft: 4, width: '90%',}}container direction="row" alignItems="center">
            <Grid item='true'>
              <FilterListIcon style={{fontSize: 30}} htmlColor='#FFFFFF'></FilterListIcon>
            </Grid>
            <Grid item='true'>
              <Typography sx={{marginLeft: 0.5}} color="secondary" variant="h3">
                Filter
              </Typography>
            </Grid>
          </Grid>
          
          <Divider variant="middle" />

          <Grid sx={{marginTop: 1, marginLeft: 2.6, width: '90%', minHeight: 80,}}>
            <Grid item='true'>
              <Typography sx={{marginLeft: 0.5, }} color="secondary" variant="h4">
                Active Filters:
              </Typography>
            </Grid>
            
            <Box flexWrap='wrap'>
              { Object.keys(props.filters).map(key => mapFiltersToDisplay(key)) }
            </Box>
            { 
            (atLeastOneFilter()) &&
            <Grid item sx={{alignSelf: "flex-end"}}>
            <Button onClick={() => {handleClearAll();}} style={{textTransform: 'none'}} sx={{paddingTop: 1, paddingRight: 1, width: 'fit-content', marginLeft: 22}}>
              <Typography sx={{color: "#009be5"}} variant="p">
                Clear All Filters
              </Typography>
            </Button>
            </Grid>
            }
            
          </Grid>

          <Divider variant="middle" />

          <Grid sx={{marginTop: 1, marginLeft: 2.6, width: '90%', marginBottom: 2, display: 'flex', flexDirection: 'row',}} alignItems="center">
            <Grid item='true'>
              <Typography sx={{marginLeft: 0.5, fontSize: 14 }} color="secondary" variant="h4">
                Show Only Patients with DICOMs
              </Typography>
            </Grid>

            <Grid sx={{marginLeft: 1, }} item='true'>
              <Checkbox color='primary'  sx={{ color: 'white',}}
                checked={props.filters["requirestudies"]} onChange={handleStudyChange}>
              </Checkbox>
            </Grid>
          </Grid>


          <FilterList
            filterCategory={'Cohorts'}
            theme={theme}
            filters={props.filters}
            setFilters={props.setFilters}
            handleSelectedFilter={handleSelectedFilter}
            handleAgeSubmit={handleAgeSubmit}
            handleCustomIDsSubmit={handleCustomIDsSubmit}
            authToken={props.authToken}
            filterOptions={props.filterOptions}
          />

          <Grid sx={{display: 'flex', flexDirection: 'row',  justifyContent:"flex-end" }} container>
            <Grid item>
              <Button onClick={() => {props.FilterUpdate(true)}} style={{textTransform: 'none'}} sx={{backgroundColor: "#009be5", paddingTop: 1, paddingRight: 1,marginRight: 3, width: 'fit-content', 
                '&:hover': {
                    backgroundColor: '#009be5',
                    color: '#3c52b2',
                  },
              }}>
                <Typography sx={{ fontSize: 16}} color="secondary" variant="p">
                  Apply Filters
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
}

export default Filter;